import { useCallback, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from './TutorAvatar.module.scss';
import classes from 'classnames';
import TutorIcon from '@/assets/svg/tutor/tutorDashboard.svg';
import { useAppDispatch, useAppSelector } from '@/store';
import TutorChatBody from '../TutorDialogueManager/TutorChatBody';
import { tutorActions, tutorSelector } from '@/store/reducers/tutor';
import { TriggeredBy } from '@/types/tutor';
import AvatarStream from '../../AvatarStream';

const SHOW_AVATAR = true;

const TutorAvatar = () => {
  const [ isAvatarReady, setIsAvatarReady ] = useState<boolean>(!SHOW_AVATAR);
  const [ pauseRecording, setPauseRecording ] = useState<boolean>(false);
  const [ topicId, setTopicId ] = useState<string>('');
  const [ ShowTeacherReport, setShowTeacherReport ] = useState<boolean>(false);
  const avatarRef = useRef<any>(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(tutorActions.openTutor(TriggeredBy.TutorDemo));

    return () => {
      dispatch(tutorActions.closeTutor());
    };
  }, []);

  const onAvatarReadyHandle = useCallback(() => {
    setIsAvatarReady(true);
  }, []);

  const onSpeakHandle = useCallback((text: string) => {
    if (avatarRef.current) {
      avatarRef.current.speak(text);
    }
  }, []);

  const onAvatarStartTalkingHandle = useCallback(() => {
    setPauseRecording(true);
  }, []);

  const onAvatarStopTalkingHandle = useCallback(() => {
    setPauseRecording(false);
  }, []);

  const onTopicSelectHandle = useCallback((topicId: string) => {
    setTopicId(topicId);
  }, []);

  const onReportHandle = useCallback((showReport: boolean) => {
    setShowTeacherReport(showReport);
  }, []);

  const content = (<>
    <div className={classNames.chat} data-id="TutorChatBody">
      {isAvatarReady && <TutorChatBody 
        speak={onSpeakHandle} 
        pauseRecording={pauseRecording} 
        className={classNames.tutor_chat_body} 
        onTopicSelect={onTopicSelectHandle} 
        onReport={onReportHandle}
      />}
    </div>
    <div className={classNames.avatar}>
      {SHOW_AVATAR && !ShowTeacherReport && <AvatarStream 
        onReady={onAvatarReadyHandle} 
        onStartTalking={onAvatarStartTalkingHandle} 
        onStopTalking={onAvatarStopTalkingHandle} 
        ref={avatarRef} 
        topicId={topicId} 
      />}
    </div>
  </>
  );

  return content;
};

export default TutorAvatar;
